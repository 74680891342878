<template>
    <v-card white class="mt-0">
        <div class="d-flex">
            <v-avatar class="ma-3" size="100" tile>
                <v-img :src="product.thumbnail_file.url" contain></v-img>
            </v-avatar>

            <div>
                <v-card-title
                    class="subtitle-2 pb-0"
                    v-text="product.product_name"
                ></v-card-title>

                <v-card-text>
                    <div class="my-4 text-subtitle-1">
                        <h4>₹ {{ price }}</h4>
                    </div>
                    <v-row align="center" justify="end" class="mx-0">
                        {{ unit }}
                        <v-spacer></v-spacer>
                        <v-card-actions>
              <span class="orange" style="color: #fff; border-radius: 25px">
                <v-icon class="ml-1 mr-1" @click="removeFromCart(product)" dark>
                  mdi-minus
                </v-icon>
                {{ quantity }}
                <v-icon class="ml-1 mr-1" @click="addToCart(product)" dark>
                  mdi-plus
                </v-icon>
              </span>
                        </v-card-actions>
                    </v-row>
                </v-card-text>
            </div>
        </div>
    </v-card>
</template>


<script>
export default {
    name: "CartProductComponent",

    props: ["product", "price", "sku", "product_variant_id"],

    data: () => ({
        quantity: 0,
        unit: "",
    }),

    created() {
        if (this.$store.state.cart) {
            if (this.containsKey(this.$store.state.cart.items, this.product.id)) {
                this.quantity = this.$store.state.cart.items[this.product.id].quantity;
            }
        }

        this.unit = this.product.product_unit;
        if(this.product_variant_id){
            for (var keyObj in this.product.product_variant) {
                if(this.product.product_variant[keyObj].id == this.product_variant_id){
                    this.unit = this.product.product_variant[keyObj].attribute_value.name;
                }
            }
        }
    },

    methods: {
        addToCart(pro) {
            var obj = {
                product: pro,
                sku: "",
                product_variant_id: null,
                price: 0,
                quantity: 0,
            };

            if (this.$store.state.cart) {
                if (!this.containsKey(this.$store.state.cart.items, pro.id)) {
                    this.$set(this.$store.state.cart.items, pro.id, obj);
                    this.$store.state.cart.uniqueSkuInCart++;
                }
            } else {
                this.$set(this.$store.state.cart.items, pro.id, obj);
                this.$store.state.cart.uniqueSkuInCart++;
            }

            this.$store.state.cart.items[pro.id].quantity++;

            var price = pro.price;
            this.$store.state.cart.items[pro.id].sku = pro.sku;
            this.$store.state.cart.items[pro.id].price = pro.price;
            /*if (this.variantInformation) {
                this.$store.state.cart.items[pro.id].product_variant_id = this.variantInformation.id;
                this.$store.state.cart.items[pro.id].sku = this.variantInformation.sku;
                this.$store.state.cart.items[pro.id].price = this.variantInformation.price;
                price = this.variantInformation.price;
            }*/
            this.quantity++;
            this.$store.state.cart.count++;
            this.$store.state.cart.total -= parseInt(this.$store.state.cart.items[pro.id].price);

            localStorage.setItem("cart", JSON.stringify(this.$store.state.cart));
        },

        removeFromCart(pro) {
            if (this.$store.state.cart) {
                if (!this.containsKey(this.$store.state.cart.items, pro.id))
                    return false;
            } else return false;

            this.$store.state.cart.items[pro.id].quantity--;
            this.quantity--;
            this.$store.state.cart.count--;
            this.$store.state.cart.total -= parseInt(pro.price);

            if (this.quantity == 0) {
                this.$delete(this.$store.state.cart.items, pro.id);
                this.$store.state.cart.uniqueSkuInCart--;
            }

            localStorage.setItem("cart", JSON.stringify(this.$store.state.cart));
        },

        containsKey(obj, key) {
            for (var keyObj in obj) {
                if (keyObj == key) return true;
            }
            return false;
        },
    },
};
</script>
